html {
  font-size: 16px;
  overflow: hidden;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 0.875rem;
  overflow-x: hidden !important;
  line-height: 1.5;
  letter-spacing: 0.3px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  overflow: hidden;
}
